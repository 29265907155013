var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "kt-container kt-grid__item kt-grid__item--fluid w-100 pl-0 pr-0",
    },
    [
      _c(
        "div",
        {
          staticClass:
            "kt-grid kt-grid--desktop kt-grid--ver kt-grid--ver-desktop kt-app",
        },
        [
          _c(
            "div",
            {
              staticClass:
                "kt-grid__item kt-grid__item--fluid kt-app__content ml-0",
            },
            [_c("router-view")],
            1
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }